export const TEXT_STYLE_BLUE = {
    fontFamily: 'Game_Font',
    color: '#bbfcff',
    shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 }
}
export const TEXT_STYLE_GREEN = {
    fontFamily: 'Game_Font',
    color: '#79ff84',
    shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 }
}
export const TEXT_STYLE_RED = {
    fontFamily: 'Game_Font',
    color: '#FF7979',
    shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 }
}
export const TEXT_STYLE_ORANGE = {
    fontFamily: 'Game_Font',
    color: '#ffedbb',
    shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 }
}
export const TEXT_STYLE_GREY = {
    fontFamily: 'Game_Font',
    color: '#b6b6b6',
    shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 }
}


// export default class GameText extends Phaser.GameObjects.Text {
//     constructor(scene, x, y, text, style) {
//         super(scene, x, y, text, style);
//         scene.add.existing(this);
//     }
// }