import { updateUI } from '../../../../../scenes/ui/UI.js';
import apiClient from '../../../../../services/apiClient.js';
import { TEXT_STYLE_GREEN } from '../../../../../utils/textStyles.js';
import LeaveBattleButton from '../../../../main/buttons/battle/LeaveBattleButton.js';
import UnitIcon from '../../../../main/icons/UnitIcon.js';
import SingleInfoTable from '../common/SingleInfoTable.js';


export default class BattleResultTable extends SingleInfoTable {

    create() {
        super.create();
        this.setIconPositions();
        const h = this.height;
        this.add(this.leaveButton = new LeaveBattleButton(this.scene, 0, h * 0.063, [], 'button_large', h * 0.145, h * 0.145, 'green'));
    }

    async update(category) {
        await super.update(category);
        const data = this.parentContainer.getData('data');
        const army = data['army'];

        this.clear();

        this.addCommander(army['commander'].first);
        this.addCharacter(army['character'].first);
        this.addMainTroop(army['main_troop']);
        this.addExtraTroop(army['extra_troop']);
        this.addKilledEnemies();

        this.updateData(army);
    }

    addCommander(icon) {
        const pos = this.iconPositions;
        const w = this.width;
        const h = this.height;

        const unitIcon = new UnitIcon(this.scene, pos[0].x, pos[0].y, [], 'button', h * 0.11, h * 0.11);
        unitIcon.setUnitData(icon.unitData, icon.index);
        this.add(unitIcon);

        this.add(this.xp = this.scene.add.text(w * -0.316, h * 0.085, `+${icon.unitData['receivedXP'] || 0} XP`).setOrigin(0, 1));
        this.xp.setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.02);

        unitIcon.obj.removeInteractive();
    }

    addCharacter(icon) {
        const pos = this.iconPositions;
        const w = this.width;
        const h = this.height;

        const unitIcon = new UnitIcon(this.scene, pos[6].x, pos[6].y, [], 'button', h * 0.11, h * 0.11);
        unitIcon.setUnitData(icon.unitData, icon.index);
        this.add(unitIcon);

        this.add(this.xp = this.scene.add.text(w * 0.316, h * 0.085, `+${icon.unitData['receivedXP'] || 0} XP`).setOrigin(1, 1));
        this.xp.setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.02);

        unitIcon.obj.removeInteractive();
        unitIcon.flip();
    }

    addMainTroop(troop) {
        const pos = this.iconPositions;
        const w = this.width;
        const h = this.height;
        let xpX = w * 0.022;
        let xpY = h * 0.02;
        let count = 1;

        troop.each(icon => {
            const unitIcon = new UnitIcon(this.scene, pos[count].x, pos[count].y, [], 'button', h * 0.095, h * 0.095);
            unitIcon.setUnitData(icon.unitData, icon.index);
            unitIcon.obj.removeInteractive();
            this.add(unitIcon);

            this.add(this.xp = this.scene.add.text(pos[count].x + xpX, pos[count].y + xpY, `+${icon.unitData['receivedXP'] || 0} XP`).setOrigin(0, 1));
            this.xp.setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.02);

            count++;
        });
    }

    addExtraTroop(troop) {
        const pos = this.iconPositions;
        const w = this.width;
        const h = this.height;
        let xpX = w * 0.022;
        let xpY = h * 0.02;
        let count = 1;

        troop.each(icon => {
            const unitIcon = new UnitIcon(this.scene, pos[count + 6].x, pos[count + 6].y, [], 'button', h * 0.095, h * 0.095);
            unitIcon.setUnitData(icon.unitData, icon.index);
            unitIcon.obj.removeInteractive();
            unitIcon.flip();
            this.add(unitIcon);

            this.add(this.xp = this.scene.add.text(pos[count + 6].x - xpX, pos[count + 6].y + xpY, `+${icon.unitData['receivedXP'] || 0} XP`).setOrigin(1, 1));
            this.xp.setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.02);

            count++;
        });
    }

    async addKilledEnemies() {
        const data = this.scene.game.registry.get('battle_data');
        const battleScene = this.scene.scene.get(data['data']['data']['scene']);
        const enemies = battleScene.data.get('enemies_killed');

        const w = this.width;
        const h = this.height;
        let dist = 0 // Расстояние между иконками врагов
        let posX = w * -0.291;
        let posY = h * -0.27;
        let textOffsetX = w * -0.263;
        let textOffsetY = h * -0.25;

        for (let enemy in enemies) {
            this.add(this.icon = this.scene.add.sprite(posX + dist, posY, 'animal_icons', enemy + '.png').setOrigin(0.5, 0.5).setDisplaySize(h * 0.095, h * 0.095));

            this.add(this.text = this.scene.add.text(textOffsetX + dist, textOffsetY, enemies[enemy].toString()).setOrigin(0, 1));
            this.text.setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.023);

            dist += w * 0.135;
        }
    }

    setIconPositions() {
        const w = this.width;
        const h = this.height;

        this.iconPositions = [
            { x: w * -0.341, y: h * 0.06 },
            { x: w * -0.312, y: h * 0.15 },
            { x: w * -0.285, y: h * 0.234 },
            { x: w * -0.192, y: h * 0.066 },
            { x: w * -0.164, y: h * 0.15 },
            { x: w * -0.136, y: h * 0.234 },

            { x: w * 0.34, y: h * 0.06 },
            { x: w * 0.312, y: h * 0.15 },
            { x: w * 0.285, y: h * 0.234 },
            { x: w * 0.192, y: h * 0.066 },
            { x: w * 0.164, y: h * 0.15 },
            { x: w * 0.136, y: h * 0.234 },
        ]
    }

    getArmyData(army) {
        // Battle data
        const data = this.scene.game.registry.get('battle_data');
        const battleScene = this.scene.scene.get(data['data']['data']['scene']);
        const enemies = battleScene.data.get('enemies_total');
        const killedEnemies = battleScene.data.get('enemies_killed');

        // Army data
        const commander = army['commander'].first.unitData;
        const character = army['character'].first.unitData;
        const mainTroop = [];
        const extraTroop = [];

        army['main_troop'].each(icon => {
            mainTroop.push(icon.unitData);
        });
        army['extra_troop'].each(icon => {
            extraTroop.push(icon.unitData);
        });

        // Rewards
        const extraRewards = (this.texture == 'battle_defeat') ? {} : battleScene.data.get('extra_rewards');

        return {
            barrier: data['data']['data']['barrier'] ?? null,
            id: data['data']['unit'].Id,
            scene: data['data']['unit'].scene.scene.key,
            enemies: enemies,
            killedEnemies: killedEnemies,
            army: {
                commander: commander,
                character: character,
                main_troop: mainTroop,
                extra_troop: extraTroop,
            },
            extraRewards: extraRewards
        };
    }

    async updateData(army) {
        const response = await apiClient.battle.finish(this.getArmyData(army));

        if (response['status'] != 'success') {
            return;
        }

        this.addMainRewards(response['rewards']);
    }

    addMainRewards(rewards) { }

    clear() {
        this.each(obj => {
            if (![this.bg, this.leaveButton].includes(obj)) {
                obj.destroy();
            }
        })
    }
}



