import sceneNames from "../../../../../../config/scenes/sceneNames";
import { TEXT_STYLE_ORANGE, TEXT_STYLE_RED } from "../../../../../../utils/textStyles";
import { ChangeSceneButton } from "../../../../../main/buttons/ChangeSceneButton";

export default class BattleSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, targetScene, battleAmount, battleData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.width = tw * 0.65;
        this.height = th * 0.12;
        this.targetScene = targetScene;
        this.battleAmount = battleAmount;
        this.battleData = battleData;

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.buttonFrame = this.scene.add.sprite(w * 0.29, 0, 'UI', 'hex.png').setDisplaySize(h * 0.045, h * 0.045));
        this.add(this.buttonScene = new ChangeSceneButton(this.scene, w * 0.29, 0, [], 'table_button', h * 0.045, h * 0.045, 'green', this.targetScene));
        this.add(this.locationMark = this.scene.add.sprite(w * 0.29, 0, 'battle_ui', 'location_mark.png').setDisplaySize(h * 0.025, h * 0.025).setTintFill(0x79ff84));

        this.add(this.sceneName = this.scene.add.text(w * -0.3, h * -0.01, sceneNames[this.targetScene]).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022));
        this.add(this.amountText = this.scene.add.text(w * 0.27, h * -0.01, `Угрозы: ${this.battleAmount}`).setOrigin(1, 1).setStyle(TEXT_STYLE_RED).setFontSize(h * 0.022));

        this.add(this.line = this.scene.add.line(0, 0, 0, 0, 0, 0, 0xb0f1ff).setAlpha(0.8));
        this.line.setTo(w * -0.3, 0, w * 0.27, 0);

        const enemies = this.getEnemies();
        this.addEnemies(enemies);
    }

    getEnemies() {
        const scene = this.targetScene;
        const data = this.battleData[scene];
        const enemies = {};
        
        for (let id in data) {
            let unit = data[id]['unit'];
            if (enemies[unit]) {
                continue;
            } 
            enemies[unit] = 1;
        }

        return enemies;
    }

    addEnemies(enemies) {
        const w = this.tw;
        const h = this.th;
        let dist = 0;

        for (let name in enemies) {
            let icon = this.scene.add.sprite(w * -0.3 + dist, h * 0.01, 'animal_icons', name + '.png').setOrigin(0, 0);
            if (!icon.texture.has(name + '.png')) {
                icon.setTexture('unit_icons', name + '.png');
            }
            if (!icon.texture.has(name + '.png')) {
                icon.setTexture('animal_icons', 'zazulic.png');
            }
            icon.setDisplaySize(h * 0.08, h * 0.08);
            this.add(icon);
            dist += w * 0.05;
        }
    }
}