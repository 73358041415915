import { getRandomArrayElement } from "../../../../utils/utils";
import { MapUnit } from "./MapUnit";

export class MapPichmog extends MapUnit {

    pointerOver() {
        super.pointerOver();
        this.isPointerOver = true;

        if (this.obj.anims.isPlaying) {
            return;
        }

        this.obj.anims.play('pichmog_wake_' + this.dir);

        this.obj.on('animationcomplete', () => {
            this.obj.off('animationcomplete');
            if (!this.isPointerOver) {
                this.obj.anims.playReverse('pichmog_wake_' + this.dir);
            }
        });
    }

    pointerOut() {
        super.pointerOut();
        this.isPointerOver = false;

        if (this.obj.anims.isPlaying) {
            return;
        } else {
            this.obj.anims.playReverse('pichmog_wake_' + this.dir);
        }
    }

    playAnimation() {
        this.obj.setTexture('pichmog_wake');
        const dirs = ['down-r', 'down-l', 'up-r', 'up-l'];
        this.dir = getRandomArrayElement(dirs);

        switch (this.dir) {
            case 'down-r':
                this.obj.setFrame(0);
                break;
            case 'down-l':
                this.obj.setFrame(5);
                break;
            case 'up-r':
                this.obj.setFrame(10);
                break;
            case 'up-l':
                this.obj.setFrame(15);
                break;
        }

        this.setUnitSize();
    }
};