import JournalScrollItem from "./JournalScrollItem";
import JournalSideScroll from "./JournalSideScroll";

export default class JournalScrollCharacters extends JournalSideScroll {

    addItems() {
        const characters = this.scene.journalData['characters']; // DB
        const data = this.scene.journalData['data']['characters']; // config

        for (let index in characters) {
            const d = data[characters[index]['name']];
            let shortName = d['short_name'];
            const status = characters[index]['rewards_status'];

            let cell = new JournalScrollItem(this.scene, 0, 0, [], 'journal_character_icons', characters[index]['name'], shortName);
            this.scroll.addItem(cell);

            if (status == 'opened') {
                cell.complete();
            } else if (status == 'claimed') {
                cell.claim();
            }
        }
    }
}