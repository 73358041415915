import TimeManager from "../../../managers/TimeManager";
import InfoTables from "../../../scenes/ui/InfoTables";
import InteractiveObject from "../../main/InteractiveObject";
import ProcessIcon from "../../ui/common/ProcessIcon";
import BarrierLink from "../object_links/BarrierLink";
import BuildingLink from "../object_links/BuildingLink";
import SceneObjectLink from "../object_links/SceneObjectLink";
import UnitLink from "../object_links/UnitLink";

export default class MapObject extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, data = {}) {
        super(scene, x, y, children, texture, width, height);

        this.linkX = data['link_x'] * this.obj.displayWidth;
        this.linkY = data['link_y'] * this.obj.displayHeight;
        this.name = data['name'];
        this.objectData = data; // Это для использования вне класса 

        this.processStackData = [];

        this.addLink();
        this.makeInteractive();
    }

    create() {
        super.create(this.objectData);
        this.obj.setDisplaySize(this.scene.mapW * this.width, this.scene.mapW * this.height);
        this.setPosition(this.scene.mapW * this.x, this.scene.mapW * this.y);
    }

    addLink() {
        switch (this.type) {
            case 'building':
                this.add(this.link = new BuildingLink(this.scene, 0, 0, [], this.name, this));
                break;
            case 'barrier':
                this.add(this.link = new BarrierLink(this.scene, 0, 0, [], this.name, this.objectData));
                break;
            case 'scene':
            case 'world_area':
                this.add(this.link = new SceneObjectLink(this.scene, 0, 0, [], this.name));
                break;
            case 'unit':
                this.add(this.link = new UnitLink(this.scene, 0, 0, [], this.name, this.objectData));
                break;
        }

        this.link?.setPosition(this.linkX, this.linkY);
        const size = this.link ? this.link.link.displayHeight * 0.64 : 0;

        this.add(this.processLink = this.scene.add.image(this.linkX, this.linkY, 'UI', 'process_link.png').setDisplaySize(size, size).setOrigin(0, 1).setTintFill(0x79ff84).setAlpha(0));
    }

    makeInteractive() {
        this.obj.setInteractive(this.scene.input.makePixelPerfect());
        super.makeInteractive();
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
        this.link?.show();
        this.processLink.setAlpha(0);

        for (let i = 1; i < this.processStackData.length; i++) {
            this.processStackData[i].setVisible(true);
        }
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
        this.link && this.link.hide();

        if (this.processStackData.length) {
            this.processLink.setAlpha(1);

            for (let i = 1; i < this.processStackData.length; i++) {
                this.processStackData[i].setVisible(false);
            }
        }
    }

    setState(state) {
        this.state = state;
    }

    getProcessFromStack(name) {
        return this.processStackData.find(icon => icon.info['name'] == name);
    }

    addProcessToStack(data) {
        console.log(this.processStackData, data);

        const processExists = this.getProcessFromStack(data[['name']]);
        if (processExists) return;

        let icon = new ProcessIcon(this.scene, 0, 0, [], data);
        this.processStackData.push(icon);
        this.add(icon);

        const timerScene = this.scene.scene.get('InfoTables');
        TimeManager.createTimer(timerScene, `${data['name']}_stack`, data['duration'],
            () => this.removeProcessFromStack(icon),
            (remainingTime) => {
                if (icon) icon.update(remainingTime);
            }
        );

        this.processLink.setAlpha(1);
        this.updateProcessStack();
    }

    removeProcessFromStack(icon) {
        if (!icon) {
            return;
        }
        TimeManager.removeTimer(icon.info['name']);
        TimeManager.removeTimer(`${icon.info['name']}_stack`);

        const index = this.processStackData.indexOf(icon);
        this.processStackData.splice(index, 1);
        icon.destroy();

        if (!this.processStackData.length) {
            this.processLink.setAlpha(0);
        }
        this.updateProcessStack();
    }

    updateProcessStack() {
        if (!this.processStackData.length) {
            return;
        }
        this.processStackData[0].setVisible(true);
        const wh = window.screen.height;
        const coeff = this.link.flipped ? -1 : 1;
        const linkW = this.link.link.displayWidth;
        const linkH = this.link.link.displayHeight;
        const startX = this.link.x + coeff * (linkW * 0.1 + wh * 0.043);
        const startY = this.link.y - linkH * 0.87 + wh * 0.027;
        const startSize = window.screen.height * 0.05;

        // The main process position
        this.processStackData[0].setPosition(startX, startY);
        this.processStackData[0].updateInfo(startSize);

        const x = this.link.x + coeff * (linkW * 0.3);
        const y = this.link.y - linkH * 0.55;
        const size = window.screen.height * 0.025;

        // The rest of the processes
        for (let i = 1; i < this.processStackData.length; i++) {
            const icon = this.processStackData[i];
            icon.setPosition(x + coeff * (wh * 0.03 * (i - 1)), y);
            icon.updateInfo(size, false);
        }
    }
}