import InfoTables from "../../../scenes/ui/InfoTables";
import MapObject from "./MapObject";
import apiClient from "../../../services/apiClient";
import AudioManager from "../../../managers/AudioManager";
import OpenBarrierTable from "../link_tables/OpenBarrierTable";
import eventBus from "../../../services/eventBus";
import barrierData from "../../../config/scenes/barrierData";

export default class Barrier extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data) {
        super(scene, x, y, children, texture, width, height, data);
    }

    create() {
        this.type = 'barrier';
        super.create();

        // Container for lookout icons that are displayed above the barrier
        this.add(this.lookoutIcons = this.scene.add.container(0, 0, []));

        // Update icons visibility once the amount of the lookouts has changed.
        eventBus.on(`changeLookout${this.texture}`, (lookouts) => this.updateLookoutIcons(lookouts));
    }

    /**
     * Updates the icons above the barrier to show, how many units are currently in lookout. 
     * @param {number} lookouts The amount of units in the lookout. 
     */
    updateLookoutIcons(lookouts) {
        this.lookoutIcons.removeAll(true);

        const data = barrierData[this.texture]['icons'];
        const size = window.screen.height * 0.03;
        const ow = this.obj.displayWidth;
        const oh = this.obj.displayHeight;

        if (!this.scene) {
            return;
        }

        // Create icons
        for (let i = 0; i < lookouts; i++) {
            this.lookoutIcons.add(this.scene.add.sprite(data[i]['x'] * ow, data[i]['y'] * oh, 'unit_category_icons', 'soldier.png').setDisplaySize(size, size));
        }
    }

    async checkAvailable() {
        const data = await apiClient.buildings.checkAvailable({
            scene: this.scene.scene.key,
            name: this.texture
        });

        if (data['status'] != 'success') {
            return;
        }
        return data['available'];
    }

    pointerOver() {
        super.pointerOver();
        this.link.hide();
        if (!this.tableFix) {
            this.link.show();
        }
    }

    async click() {
        const isAvailable = await this.checkAvailable();

        if (!isAvailable) {
            InfoTables.showWarningText('Уничтожьте врагов на пути к строению');
            return;
        }

        super.click();
        this.link.setAlpha(0.001);
        this.scene.game.registry.set('curBuilding', this);

        switch (this.state) {
            case 'update':
                InfoTables.showWarningText('Будет доступно в одном из обновлений');
                break;

            case 'closed':
                if (this.tableFix) {
                    this.destroyFixTable();
                } else {
                    this.openFixTable();
                }

                this.scene.input.on('pointerdown', () => {
                    if (this.tableFix) this.destroyFixTable();
                });
                break;

            case 'opened':
                InfoTables.openInfoTable('battles', { position: 'left' });
                AudioManager.playSound('click', 'building');
                break;
        }
    }

    openFixTable() {
        this.scene.map.add(this.tableFix = new OpenBarrierTable(this.scene, this.x + this.link.x, this.y + this.link.y, []));
    }

    destroyFixTable() {
        this.tableFix && this.tableFix.destroy();
        this.tableFix = null;
        this.scene.map.handleMoves();
    }

    upgrade() {
        this.setState('opened');
        this.setLevel(++this.level);
        this.updateInfo(this.level);
        this.deleteBuilder();
    }

    async updateInfo(data) {
        this.setState(data['opened'] ? 'opened' : 'closed');
    }

    displayLookoutIcon() {

    }
}