/**
 * @module managers/TimeManager
 * @classdesc
 * A class that controls game timers and provides logic for convinient time formatting.
 */
export default class TimeManager {
    static timers = new Map();
    static textTimers = new Map();

    /**
     * Creates a timer with specific key and adds it to `timers` Map array.
     * @static
     * @param {string} scene The scene the timer is registered on. All of the timers are registered on `InfoTables`.
     * @param {string} key Name of the timer it will be identified by.
     * @param {number} duration Duration of the timer in seconds.
     * @param {Function} mainCallback A function that is called once the time elapsed.
     * @param {Function} updateCallback A function that is called on the timer update every `N` ms.
     */
    static createTimer(scene, key, duration, mainCallback, updateCallback) {
        this.removeTimer(key);

        /**
         * @constant
         * The main timer that is contained in `timers`.
         * Add 1 second `1000 ms` to the time as the way to avoid premature timer elapse.
         */
        const timer = scene.time.addEvent({
            delay: duration * 1000 + 1000,
            callback: () => {
                this.removeTimer(key);
                mainCallback();
            }
        });

        this.timers.set(key, timer);

        /**
         * @constant
         * The loop timer that fires every second and calles the `updateCallback()`. Contained in `textTimers`.
         */
        const textTimer = scene.time.addEvent({
            delay: 1000,
            loop: true,
            callback: () => {
                const remainingTime = Math.ceil((timer.delay - timer.elapsed) / 1000);
                updateCallback(remainingTime);
            }
        });

        this.textTimers.set(key, textTimer);
    }

    /**
     * Deletes the timer with the specific key.
     * @static
     * @param {string} key Name of the timer that must be removed.
     */
    static removeTimer(key) {
        const timer = this.timers.get(key);
        const textTimer = this.textTimers.get(key);

        if (timer) {
            textTimer.remove();
            timer.remove();
            this.timers.delete(key);
        }
    }

    /**
     * Formats the time to the pattern `00:00:00`.
     * @static
     * @param {number} duration Duration of the timer in seconds.
     * @returns {string} The formatted time.
     */
    static formatFullTime(duration) {
        const seconds = Math.floor(duration % 60);
        const minutes = Math.floor((duration / 60) % 60);
        const hours = Math.floor((duration / (60 * 60)) % 24);
        const days = Math.floor(duration / (60 * 60 * 24));

        const timeComponents = [];

        if (days > 0) {
            timeComponents.push(days.toString());
            timeComponents.push(hours.toString().padStart(2, '0'));
        } else if (hours > 0) {
            timeComponents.push(hours.toString());
        }

        timeComponents.push(minutes.toString().padStart(2, '0'));
        timeComponents.push(seconds.toString().padStart(2, '0'));

        return timeComponents.join(':');
    }

    /**
     * Formats the time to the pattern `1с, 2м, 3ч, 4д`.
     * @static
     * @param {number} seconds Duration of the timer in seconds.
     * @returns {string} The formatted time.
     */
    static formatShortTime(seconds) {
        let time = '';

        if (seconds >= 86400) {
            const days = Math.floor(seconds / 86400);
            time = days + 'д';

        } else if (seconds >= 3600) {
            const hours = Math.floor(seconds / 3600);
            time = hours + 'ч';

        } else if (seconds >= 60) {
            const minutes = Math.floor(seconds / 60);
            time = minutes + 'м';

        } else {
            time = seconds + 'с';
        }

        return time;
    }

    /**
     * Returns the time left of the timer with specific key.
     * @static
     * @param {string} key Name of the timer.
     * @returns {number} The remaining time of the timer.
     */
    static getRemaining(key) {
        const timer = this.timers.get(key);

        if (!timer) {
            return 0;
        }

        return this.timers.get(key).getRemaining();
    }
}

