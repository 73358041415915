const adsAchievementData = {
    1: {
        name: "Повысить уровень Тони",
        category: "upgrade",
    },
    2: {
        name: "Получить билеты",
        category: "ticket",
    },
    3: {
        name: "Обменять билеты",
        category: "ticket",
    },
    4: {
        name: "Получить золотые билеты",
        category: "golden_ticket",
    },
    5: {
        name: "Обменять золотые билеты",
        category: "golden_ticket",
    },
    6: {
        name: "Обменять билеты на золотые",
        category: "golden_ticket",
    },
    7: {
        name: "Купить билеты",
        category: "morion",
    },
    8: {
        name: "Купить золотые билеты",
        category: "morion",
    },
};

Object.defineProperty(adsAchievementData, "rewards", {
    value: {
        ticket: 1,
        xp: 1,
    },
    enumerable: false, 
    writable: true,   
    configurable: true 
});

export default adsAchievementData;