import adsAchievementData from "../../../../../config/ads/adsAchievementData";
import eventBus from "../../../../../services/eventBus";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../utils/textStyles";
import SingleInfoTable from "../common/SingleInfoTable";
import AchievementScroll from "../player/components/AchievementScroll";
import TicketChangingCell from "./components/TicketChangingCell";

export default class AdsAchievementTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.title = this.scene.add.text(w * 0.015, h * -0.317, 'Достижения:').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));
        this.add(this.finishedText = this.scene.add.text(w * 0.12, h * -0.317, '100').setOrigin(0, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));

        // Achievement scroll
        this.add(this.achievementScroll = new AchievementScroll(this.scene, 0, 0, [], w, h, adsAchievementData));

        // Character info
        this.add(this.level = this.scene.add.text(w * -0.308, h * -0.295, '1').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.03));
        this.add(this.username = this.scene.add.text(w * -0.28, h * -0.317, 'Режиссер Тони').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        this.add(this.bar = this.scene.add.image(w * -0.2776, h * -0.31, 'UI', 'profile_bar.png')
            .setOrigin(0, 0).setDisplaySize(w * 0.2766, h * 0.0139).setCrop(0, 0, 0, 0));

        this.add(this.xpText = this.scene.add.text(w * -0.215, h * -0.255, '0').setOrigin(1, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));
        this.add(this.maxXpText = this.scene.add.text(w * -0.2, h * -0.255, '0').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        this.addChangingCells(w, h);

        eventBus.on('update-ads-content', (data) => {
            this.changingContainer?.each(cell => cell.updateText());
            this.updateAchievements(data);
        });

        eventBus.on('ads-level-up', (characterData) => {
            this.updateCharacterInfo(characterData);
        });
    }

    addChangingCells(w, h) {
        const changingData = this.parentContainer.tableData['changing'];
        this.add(this.changingContainer = this.scene.add.container(w * -0.195, h * -0.13, []));
        let dist = 0;

        for (let item of changingData) {
            this.changingContainer.add(new TicketChangingCell(this.scene, 0, dist, [], w * 0.24, w * 0.06063, item));
            dist += h * 0.11;
        }
    }

    async update(category) {
        await super.update(category);
        this.updateContent();
    }

    updateContent() {
        const data = this.parentContainer.tableData;
        this.updateAchievements(data);

        const characterData = data['character'];

        this.updateCharacterInfo(characterData);
    }

    updateAchievements(data) {
        this.achievementScroll.updateContent(data);
        this.finishedText.setText(this.achievementScroll.getAchievementCount());
    }

    updateCharacterInfo(characterData) {
        this.username.setText(characterData['name']);
        this.level.setText(characterData['level']);
        this.xpText.setText(characterData['xp']);
        this.maxXpText.setText(characterData['max_xp']);

        this.updateBar(characterData['xp'], characterData['max_xp']);
    }

    updateBar(xp, maxXp) {
        const cropW = this.bar.width * (xp / maxXp);
        this.barCrop = this.barCrop || 0;
        const cropRect = new Phaser.Geom.Rectangle(0, 0, this.barCrop, this.bar.height);

        const tween = this.scene.tweens.add({
            targets: cropRect,
            width: cropW,
            duration: 500,
            ease: Phaser.Math.Easing.Sine.Out,
            onUpdate: () => {
                this.bar.setCrop(cropRect);
            },
            onComplete: () => {
                this.barCrop = cropW;
                tween.destroy();
            }
        });
    }
}