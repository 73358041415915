const animalData = {
    'zazulic': {
        category: 'animal',
        xp_cost: 2,
        size: 0.07,
        name: 'zazulic',
        health: 150,
        max_health: 150,
        armor: 10,
        damage: 25,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_mini': {
        xp_cost: 1,
        category: 'animal',
        size: 0.05,
        name: 'zazulic_mini',
        health: 75,
        max_health: 75,
        armor: 10,
        damage: 15,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_soldier': {
        xp_cost: 3,
        category: 'animal',
        size: 0.09,
        name: 'zazulic_soldier',
        health: 250,
        max_health: 250,
        armor: 25,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_sand': {
        xp_cost: 3,
        category: 'animal',
        size: 0.07,
        name: 'zazulic_sand',
        health: 250,
        max_health: 250,
        armor: 25,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_sand_soldier': {
        xp_cost: 4,
        category: 'animal',
        size: 0.09,
        name: 'zazulic_sand_soldier',
        health: 250,
        max_health: 250,
        armor: 25,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    
    'pichmog': {
        xp_cost: 5,
        category: 'animal',
        size: 0.15,
        name: 'pichmog',
        health: 500,
        max_health: 500,
        armor: 50,
        damage: 100,
        close_damage: 0,
        accuracy: 90,
        speed: 5,
        range: 1,
    },
    
    'vugda': {
        xp_cost: 7,
        category: 'animal',
        size: 0.12,
        name: 'vugda',
        health: 650,
        max_health: 650,
        armor: 50,
        damage: 150,
        close_damage: 0,
        accuracy: 50,
        speed: 12,
        range: 7,
    },
    'vugda_mini': {
        xp_cost: 4,
        category: 'animal',
        size: 0.08,
        name: 'vugda_mini',
        health: 350,
        max_health: 350,
        armor: 20,
        damage: 80,
        close_damage: 0,
        accuracy: 30,
        speed: 12,
        range: 7,
    },
    'vugda_female': {
        xp_cost: 6,
        category: 'animal',
        size: 0.11,
        name: 'vugda_female',
        health: 600,
        max_health: 600,
        armor: 45,
        damage: 125,
        close_damage: 0,
        accuracy: 45,
        speed: 12,
        range: 7,
    },
    
    'beles': {
        xp_cost: 10,
        category: 'animal',
        size: 0.17,
        name: 'beles',
        health: 750,
        max_health: 750,
        armor: 60,
        damage: 350,
        close_damage: 0,
        accuracy: 90,
        speed: 17,
        range: 1,
    },
    'beles_mini': {
        xp_cost: 6,
        category: 'animal',
        size: 0.12,
        name: 'beles_mini',
        health: 350,
        max_health: 350,
        armor: 40,
        damage: 150,
        close_damage: 0,
        accuracy: 90,
        speed: 17,
        range: 1,
    },
    'beles_female': {
        xp_cost: 8,
        category: 'animal',
        size: 0.15,
        name: 'beles_female',
        health: 650,
        max_health: 650,
        armor: 50,
        damage: 250,
        close_damage: 0,
        accuracy: 90,
        speed: 17,
        range: 1,
    },
    
    'zoris': {
        category: 'animal',
        name: 'zoris',
        health: 3000,
        max_health: 3000,
        armor: 60,
        damage: 250,
        close_damage: 0,
        accuracy: 90,
        speed: 0,
        range: 3,
    },
    
    'mutant': {
        xp_cost: 15,
        category: 'mutant',
        size: 0.2,
        name: 'mutant',
        health: 600,
        max_health: 600,
        armor: 30,
        damage: 125,
        close_damage: 125,
        accuracy: 80,
        speed: 6,
        range: 5,
    },
    
    'Chlapidol': {
        xp_cost: 25,
        category: 'character',
        size: 0.18,
        name: 'Chlapidol',
        health: 500,
        max_health: 500,
        armor: 50,
        damage: 25,
        close_damage: 75,
        accuracy: 50,
        speed: 7,
        range: 5,
    },
    
    'irradiated': {
        xp_cost: 20,
        category: 'irradiated',
        size: 0.14,
        name: 'irradiated',
        health: 750,
        max_health: 750,
        armor: 75,
        damage: 350,
        close_damage: 0,
        accuracy: 50,
        speed: 8,
        range: 5,
    },
}

export default animalData;