import InfoTables from "../../../../../scenes/ui/InfoTables";
import ItemIcon from "../../../../main/icons/ItemIcon";
import InfoTableMainScroll from "../../../common/scroll/InfoTableMainScroll";
import BuildingProductionTable from "../production/BuildingProductionTable";
import ProductionCell from "../production/components/ProductionCell";

export default class ResearchCenterTable extends BuildingProductionTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            const items = ['crystall_health', 'crystall_damage', 'crystall_accuracy', 'crystall_speed', 'crystall_range'];

            for (let item of items) {
                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.07, h * 0.07);
                icon.setIconData({ name: item, amount: data[item] });
    
                this.mainScroll.addItem(icon);
            }
        }));
    }

    async update(category) {
        await super.update(category);

        const items = this.scene.game.registry.get('items');
        this.updateMainScroll(items);
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }
}