import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import eventBus from "../../../../../../services/eventBus";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import { getItemTexture, multiplyObjectValues } from "../../../../../../utils/utils";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import ArrowButton from "../../../../../main/buttons/production/ArrowButton";
import StartProductionButton from "../../../../../main/buttons/production/StartProductionButton";


export default class TicketShopCell extends Phaser.GameObjects.Container {

    amountToProduce = 1;

    constructor(scene, x, y, children, width, height, itemName, itemData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;

        this.itemName = itemName;
        this.itemData = itemData;

        this.tickets = { [this.itemData['ticket_type']]: this.itemData['tickets'] };

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'factory_cell.png').setDisplaySize(w, h));

        this.add(this.name = this.scene.add.text(w * -0.34, h * -0.22, this.itemData['name'])
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.22));

        this.add(this.buttonIcon = this.scene.add.sprite(w * 0.467, 0, 'items_icons', 'check.png').setDisplaySize(h * 0.3, h * 0.3));
        this.add(this.button = new ActionButton(this.scene, w * 0.468, 0, [], 'table_button', h * 0.54, h * 0.54, 'green', this.buyForTicket.bind(this)));

        this.add(this.minus = new ArrowButton(this.scene, w * 0.32, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'decrease', this));
        this.add(this.plus = new ArrowButton(this.scene, w * 0.4, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'increase', this));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        this.add(this.itemIcon = this.scene.add.sprite(w * -0.435, 0, getItemTexture(this.itemName), `${this.itemName}.png`)
            .setDisplaySize(h * 0.55, h * 0.55));

        UIController.arrangeItems({
            container: this,
            itemsData: this.tickets,
            x: w * -0.3,
            y: h * 0.27,
            iconSize: h * 0.33,
            fontSize: h * 0.2,
            space: w * 0.12,
        });

        this.add(this.amountText = this.scene.add.text(w * 0.36, h * -0.22, '1').setOrigin(0.5, 0.5));
        this.updateText();
    }

    updateText() {
        const fontSize = this.height * 0.19;
        const items = this.scene.game.registry.get('items');

        let itemsData = multiplyObjectValues(this.tickets, this.amountToProduce);
        UIController.updateItemsText(items, itemsData, this.itemsText);

        this.amountText.setText(this.amountToProduce.toString());
        this.amountText.setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize);
    }

    reset() {
        this.amountToProduce = 1;
        this.buttonIcon.setFrame('production.png');

        this.plus.makeInteractive();
        this.minus.makeInteractive();
        this.button.makeInteractive();
        this.updateText();

        updateUI(this.scene);
    }

    buyForTicket() {
        apiClient.ads.buyForTickets({
            name: this.itemName,
            amount: this.amountToProduce
        })
            .then(data => {
                InfoTables.showWarningText(data['message']);
                eventBus.emit('update-ads-content', data);
            })
            .catch(error => {
                console.error(error);
            });
    }
}