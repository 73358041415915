import bridge from "@vkontakte/vk-bridge";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import eventBus from "../../../../../../services/eventBus";

/**
 * @module
 */
export default class ShopCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, data) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.addItemData(data);
    }

    /**
     * Sets the data of the item. 
     * This data contains the main information about the item and is received from `config/shop/shopData.js`.
     * @param {object} data 
     */
    addItemData(data) {
        this.setData('data', data);
        this.create(this.tw, this.th);
    }

    /**
     * Link the shop table to the cell so it may call table's methods.
     * @param {object} table 
     */
    linkTable(table) {
        this.table = table;
    }

    /**
     * Creates the content of the cell. Adds it to the scene and to the shop scroll container.
     * @param {number} w Width of the table.
     * @param {number} h Height of the table.
     */
    create(w, h) {
        const data = this.getData('data');
        const name = data['item'];

        this.add(this.cell = this.scene.add.image(0, 0, 'shop_cell').setDisplaySize(h * 0.17 * 2.23, h * 0.17));

        // Display width and height of the cell background image.
        const cw = this.cell.displayWidth;
        const ch = this.cell.displayHeight;

        this.add(this.button = new ActionButton(this.scene, cw * 0.238, ch * 0.127, [], 'shop_button', ch * 1.17, ch * 1.17 * 0.216, 'green', this.buy.bind(this)));

        // Text inside the button.
        this.add(this.butText = this.scene.add.text(cw * 0.238, ch * 0.127, '-').setOrigin(0.5, 0.5).setStyle({
            fontFamily: 'Game_Font',
            fontSize: ch * 0.13,
            color: '#8fff89',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 1 }
        }));

        let texture = 'items_icons';

        this.isContainer = name.includes('container') && !name.includes('key_container');
        this.isContainer && (texture = 'container_icons');
        const size = this.isContainer ? ch * 0.8 : ch * 0.5;

        const isModule = name.includes('soldier_module');
        isModule && (texture = 'soldier_module_icons');

        // Shine image behind the item icon.
        this.add(this.shineFX = this.scene.add.image(cw * -0.22, ch * -0.05, 'shine').setOrigin(0.5, 0.5).setDisplaySize(ch * 0.75, ch * 0.75));

        // The main (big) item icon displayed in the hex of the cell.
        this.add(this.itemIcon = this.scene.add.image(cw * -0.22, ch * -0.05, texture, `${name}.png`).setDisplaySize(size, size));

        if ((data['currency'] == 'morion') && !this.isContainer) {
            this.add(this.itemAmount = this.scene.add.text(cw * -0.17, ch * -0.03, data['amount']).setOrigin(0, 0).setStyle(TEXT_STYLE_ORANGE).setFontSize(ch * 0.15));
        }
        
        // The icon of the currency resource (`morion`) or the resource that is contained in the item, for example, in container. 
        this.add(this.miniIcon = this.scene.add.image(cw * 0.07, ch * -0.2, 'items_icons', 'morion.png'));
        this.add(this.amountText = this.scene.add.text(cw * 0.15, ch * -0.1, data['amount']).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(ch * 0.13));

        this.setSize(cw, ch);
        this.updateContent(data);
    }

    /**
     * Updates the information of the item, displaying the correct content in the cell.
     * @param {object} data 
     */
    updateContent(data) {
        const ch = this.cell.displayHeight;
        const name = data['item'];
        const cost = data['cost'];
        // Text inside the button
        let text = 'Купить';

        if (data['currency'] == 'vote') {
            text = `${cost} голос`;
            if (cost % 10 != 1) {
                text += [2, 3, 4].includes(cost % 10) ? 'а' : 'ов';
            }
        }

        this.butText.setText(text);

        let texture = 'items_icons';

        const isContainer = name.includes('container') && !name.includes('key_container');
        const isModule = name.includes('soldier_module');
        isModule && (texture = 'soldier_module_icons');

        const frame = (data['currency'] == 'morion' || isContainer) ? 'morion' : name;
        this.miniIcon.setTexture(texture).setFrame(`${frame}.png`).setDisplaySize(ch * 0.23, ch * 0.23);

        this.amountText.setText((data['currency'] == 'morion') ? cost : data['amount']);
    }

    /**
     * Opens the confirmation table that contains the main information of the item. 
     * Only works for the items that are selled for `morion`.
     * If item is needed to be payed in `VK votes`, `this.purchase()` method is called.
     * @returns 
     */
    async buy() {
        const data = this.getData('data');

        if (data['currency'] == 'vote') {
            this.purchase(data);
            return;
        } 

        let text = `Купить ключ к контейнеру за ${data['cost']} мориона?`;
        if (this.isContainer) {
            text = `Купить контейнер за ${data['cost']} мориона?`;
        }

        InfoTables.openConfirmationTable({
            title: 'Магазин',
            text: text,
            button: 'Купить'
        }, this.buyForMorion.bind(this));
    }

    /**
     * Sends a request to the server to buy the item for `morion`.
     * @returns 
     */
    async buyForMorion() {
        const data = this.getData('data');

        const response = await apiClient.shop.buyForMorion({
            name: data['item'],
            amount: data['amount']
        });

        if (response['status'] != 'success') {
            return;
        }

        // Display the notification about what item has been bought.
        InfoTables.showWarningText(response['message']);

        if (response['message'] == 'Не достаточно мориона') {
            return;
        }

        // Update information in the linked shop table
        this.table.updateInfo(response['data']);
        
        // Fire an event that something was bought (not only containers).
        eventBus.emit('buyContainer');

        if (data['item'].startsWith('container')) {
            eventBus.emit('containerGained');
        }
    }

    /**
     * Purchase the item for `VK votes`. Sends the request to the server with `VKBridge`.
     * @param {object} data 
     */
    purchase(data) {
        const scene = this.scene;

        // the linked shop table 
        const table = this.table; 

        let amount = data['amount'];

        // In the container section in `shopData.js` the amount of the item shows the amount of morion stored in a specific container, 
        // not the amount of containers. So the `amount` should be defined as `1` for containers.
        if (this.isContainer && this.getData('data')['currency'] == 'vote') {
            amount = 1;
        }

        bridge.send('VKWebAppShowOrderBox', { 
            type: 'item',
            item: data['item'] + '_' + amount
        })
        .then(async (response) => {
            if (response.success) {
                InfoTables.showWarningText('Ресурсы добавлены, спасибо за покупку!');
                updateUI(scene);
                
                // When the request is successfully managed, send a request to get the updated shop data. 
                const response = await apiClient.shop.getData();

                if (response['status'] != 'success') {
                    return;
                }

                // Update the information in the linked table.
                table.updateInfo(response['data']);
                eventBus.emit('buyContainer');

                if (data['item'].startsWith('container')) {
                    eventBus.emit('containerGained');
                }
            } else {
                InfoTables.showWarningText('Возникли ошибки при покупке');
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}