import UIController from "../../../managers/UIController";
import TimeManager from "../../../managers/TimeManager";
import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import UpgradeBuildingButton from "../../main/buttons/UpgradeBuildingButton";

export default class FixObjectTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, morionFinish = false) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.morionFinish = morionFinish;
        this.buildingData = scene.game.registry.get('curBuilding').objectData;

        this.create(window.screen.width);
    }

    create(w) {
        this.add(this.table = this.scene.add.image(0, 0, 'table_fix').setOrigin(0, 1)
            .setDisplaySize(w * 0.24, w * 0.084).setInteractive());

        const tw = this.table.displayWidth;
        const th = this.table.displayHeight;

        // Название здания
        this.add(this.name = this.scene.add.text(tw * 0.6, th * -0.77, this.buildingData['name'])
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.011));

        // Кнопка восстановить
        this.add(this.button = new UpgradeBuildingButton(this.scene, tw * 0.203, th * -0.684, [], 'table_button', tw * 0.12, tw * 0.12, 'green'));

        this.handleButtonClick();
        this.addText(tw, th);
    }

    handleButtonClick() {
        const button = this.button.obj;

        button.on('pointerdown', () => {
            this.scene.input.off('pointerdown');
            button.on('pointerup', () => {
                this.scene.map.handleMoves();
                this.scene.input.on('pointerdown', () => {
                    if (!this.scene) {
                        return;
                    }
                    this.scene.input.off('pointerdown');
                    const building = this.scene.game.registry.get('curBuilding');
                    building && building.destroyFixTable();
                });
            });
            if (this.scene) {
                this.scene.input.on('pointerup', () => this.scene.map.handleMoves());
            }
        });
    }

    addText(w, h) {
        this.add(this.timeText = this.scene.add.text(w * 0.3, h * -0.62, 'Завершить?').setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.15));

        if (this.morionFinish) {
            const building = this.scene.game.registry.get('curBuilding');
            const morionAmount = Math.ceil(TimeManager.getRemaining(building.texture) / 60000);

            this.buildingData['fixing_materials'] = {
                'morion': morionAmount,
            }
        }

        this.add(this.itemContainer = this.scene.add.container(w * 0.22, 0, []));

        UIController.arrangeItems({
            container: this.itemContainer,
            itemsData: this.buildingData["fixing_materials"],
            x: 0,
            y: h * -0.25,
            iconSize: h * 0.2,
            fontSize: h * 0.12,
            space: w * 0.185,
        });

        if (!this.morionFinish) {
            this.updateText();
        }
    }

    updateText() {
        
        const timeData = [
            3,
            5,
            15,
            30,
            1 * 60,
            3 * 60,
            6 * 60,
            12 * 60,
            1 * 24 * 60,
            2 * 24 * 60,
            3 * 24 * 60,
            4 * 24 * 60,
            5 * 24 * 60,
            6 * 24 * 60,
            7 * 24 * 60,
            8 * 24 * 60,
        ];

        let items = this.scene.game.registry.get('items');
        
        const buildingTime = timeData[this.buildingData['building_time'] - 1] * 60;
        UIController.updateItemsText(items, this.buildingData["fixing_materials"], this.itemContainer.itemsText);
        this.timeText.setText(TimeManager.formatFullTime(buildingTime));
    }

    flip() {
        this.table.setOrigin(1, 1).setFlipX(true);

        const tw = this.table.displayWidth;
        const th = this.table.displayHeight;

        this.name.setPosition(tw * -0.6, th * -0.77);
        this.button.setPosition(tw * -0.203, th * -0.684);

        this.timeText.setPosition(tw * -0.3, th * -0.62).setOrigin(1, 0);
        this.itemContainer.setPosition(tw * -0.78, 0);
    }
}
