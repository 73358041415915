import eventBus from "./eventBus";

class ApiClient {
    constructor() {
        if (!ApiClient.instance) {
            this.baseUrl = ''; // https://ssou.ru
            ApiClient.instance = this;
        }
        return ApiClient.instance;
    }

    async get(url) {
        try {
            const response = await fetch(this.baseUrl + url);

            const result = await response.json();
            return result;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async post(url, requestData) {
        try {
            const response = await fetch(this.baseUrl + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
                body: JSON.stringify(requestData),
            });

            const result = await response.json();

            const missionCompleted = localStorage.getItem('mission_completed');
            if (result['mission_completed'] || missionCompleted == 'true') {
                localStorage.setItem('mission_completed', true);
                eventBus.emit('missionCompleted');
            }

            const achievementGained = localStorage.getItem('achievement_gained');
            if (result['achievement_gained'] || achievementGained == 'true') {
                localStorage.setItem('achievement_gained', true);
                eventBus.emit('achievementGained');
            }

            eventBus.emit('updateItems', result['items']);

            return result;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    users = {
        getUserData: () => this.get('/users/get-user-data'),
        registerNewLogin: () => this.get('/users/register-new-login'),
    }

    mails = {
        getData: () => this.get('/mails/get-data'),
        claimRewards: (data) => this.post('/mails/claim-rewards', data), // id
    }

    profile = {
        claimRewards: () => this.post('/profile/claim-rewards'),
        claimExtraRewards: () => this.post('/profile/claim-extra-rewards'),
    }

    UI = {
        getData: () => this.get('/ui/get-data'),
        journal: {
            getData: () => this.get('/ui/journal/get-data'),
        }
    }

    shop = {
        getData: () => this.get('/shop/get-data'),
        buyForMorion: (data) => this.post('/shop/buy-for-morion', data),
        buyForVotes: (data) => this.post('/shop/buy-for-votes', data),
        claimReward: (data) => this.post('/shop/claim-reward', data),
    }

    ads = {
        getData: () => this.get('/ads/get-data'),
        getShopData: () => this.get('/ads/get-shop-data'),
        buyForTickets: (data) => this.post('/ads/buy-for-tickets', data),
        changeTickets: (data) => this.post('/ads/change-tickets', data),
        claimReward: (data) => this.post('/ads/claim-reward', data),
        videoReward: (data) => this.post('/ads/video-reward', data),
    }

    missions = {
        claimRewards: (data) => this.post('/missions/claim-rewards', data), // mission_id
    }

    achievements = {
        claimRewards: (data) => this.post('/achievements/claim-rewards', data), // name
    }

    locations = {
        getData: (data) => this.post('/locations/get-data', data), // scene
        world: {
            getData: () => this.get('/locations/world/get-data'),
        }
    }

    processes = {
        getAll: () => this.get('/processes/get-all'),
        getFinished: () => this.get('/processes/get-finished'),
        start: (data) => this.post('/processes/start', data), // category, name, item_amount
        finish: (data) => this.post('/processes/finish', data), // category, name
        finishByMorion: (data) => this.post('/processes/finish-by-morion', data), // category, name
    }

    production = {
        getData: (data) => this.post('/production/get-data', data), // buildingName
    }

    items = {
        store: () => this.get('/items/store'),
        collect: (data) => this.post('/items/collect', data), // key
        modules: {
            upgrade: (data) => this.post('/items/modules/upgrade', data), // name
        }
    }

    containers = {
        open: (data) => this.post('/containers/open', data), // name
        getRewards: (data) => this.post('/containers/get-rewards', data), // action
        collectRewards: (data) => this.post('/containers/collect-rewards', data), // name, action
    }

    units = {
        getData: () => this.get('/units/get-data'),
        getAll: () => this.get('/units/get-all'),
        getСharacterByName: (data) => this.post('/units/get-character-by-name', data), // name
        getByCategory: (data) => this.post('/units/get-by-category', data), // category
        getLookout: () => this.get('/units/get-lookout'),
        setupLookout: (data) => this.post('/units/setup-lookout', data), // unit_id, scene
        removeLookout: (data) => this.post('/units/remove-lookout', data), // unit_id
        inject: (data) => this.post('/units/inject', data), // id, item
        buyMedkit: (data) => this.post('/units/buy-medkit', data),
        useMedkit: (data) => this.post('/units/use-medkit', data), // id
        morionLevelUp: (data) => this.post('/units/morion-level-up', data), // id
        soldiers: {
            rankUp: (data) => this.post('/units/soldiers/rank-up', data), // id
            applyModule: (data) => this.post('/units/soldiers/apply-module', data), // soldier_id, module_name
            removeModule: (data) => this.post('/units/soldiers/remove-module', data), // soldier_id, module_name
        },
        characters: {
            usePoints: (data) => this.post('/units/characters/use-points', data), // id, parameter
            claimRewards: (data) => this.post('/units/characters/claim-rewards', data), // name
            upgradeEquipment: (data) => this.post('/units/characters/upgrade-equipment', data), // name
        },
        robots: {
            getAll: () => this.get('/units/robots/get-all'), // id
            upgradePart: (data) => this.post('/units/robots/upgrade-part', data), // name
        },
    }

    lookoutCells = {
        unlock: (data) => this.post('/lookout-cells/unlock', data), // scene
    }

    objects = {
        spaceship: () => this.get('/objects/spaceship'), // name
    }

    buildings = {
        checkAvailable: (data) => this.post('/buildings/check-available', data), // name
        getConstruction: () => this.get('/buildings/get-construction'),
    }

    barriers = {
        open: (data) => this.post('/barriers/open', data), // scene, name
    }

    machines = {
        getAll: () => this.get('/machines/get-all'),
        getByScene: (data) => this.post('/machines/get-by-scene', data), // scene
        fix: (data) => this.post('/machines/fix', data), // id, name
        start: (data) => this.post('/machines/start', data), // id
        create: (data) => this.post('/machines/create', data), // name
        install: (data) => this.post('/machines/install', data), // name, scene
        remove: (data) => this.post('/machines/remove', data), // name
        collectOre: (data) => this.post('/machines/collect-ore', data), // id, ore
        collectOreByScene: (data) => this.post('/machines/collect-ore-by-scene', data), // scene, ore
    }

    machineCells = {
        get: () => this.get('/machine-cells/'),
        unlock: (data) => this.post('/machine-cells/unlock', data), // scene
    }

    battle = {
        finish: (data) => this.post('/battle/finish', data), // id, killed_enemies
        getAll: () => this.get('/battle/get-all'),
    }

    animals = {
        claimRewards: (data) => this.post('/animals/claim-rewards', data), // animal
    }
}


const apiClient = new ApiClient();

export default apiClient;
