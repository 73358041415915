import BattleUI from "../../../scenes/ui/BattleUI";
import InfoTables from "../../../scenes/ui/InfoTables";
import { TEXT_STYLE_ORANGE } from "../../../utils/textStyles";
import { setImageSizeByHeight } from "../../../utils/utils";

export default class PlayerBattleUI extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, type = 'player') {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.type = type; // enemy?
        this.isEnemy = type == 'enemy';
        this.battleScene = scene.scene.get(scene.game.registry.get('battle_data')['data']['data']['scene']);

        this.create(window.innerHeight * 0.17);
    }

    create(h) {
        const isEnemy = this.isEnemy;
        const ratio = isEnemy ? -1 : 1;
        const Xorigin = isEnemy ? 1 : 0;

        this.add(this.avatarFrame = this.scene.add.sprite(0, 0, 'battle_ui', 'player_frame.png').setOrigin(Xorigin, 0));
        this.avatarFrame.flipX = isEnemy;
        setImageSizeByHeight(this.avatarFrame, h);

        this.add(this.lock = this.scene.add.sprite(ratio * h * 0.82, h * 0.74, 'UI', 'lock.png').setDisplaySize(h * 0.15, h * 0.15));

        this.addProgressBar(h * 0.15, Xorigin, ratio);
        this.addAvatar(h * 0.7, Xorigin);

        this.add(this.counter = this.scene.add.text(h * 0.49 * ratio, h * 0.19, '10').setOrigin(0.5, 0.5)
            .setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.1));
    }

    addProgressBar(h, Xorigin, ratio) {
        const x = this.avatarFrame.displayWidth * 0.57 * ratio;
        const y = this.avatarFrame.displayHeight * 0.29;

        this.add(this.progressFrame = this.scene.add.sprite(x, y, 'battle_ui', 'progress_frame.png').setOrigin(Xorigin, 1));
        this.add(this.progressBar = this.scene.add.sprite(x, y, 'battle_ui', 'progress_bar.png').setOrigin(Xorigin, 1));

        setImageSizeByHeight(this.progressFrame, h);
        setImageSizeByHeight(this.progressBar, h);

        this.progressBar.setTint(this.isEnemy ? 0xFF0000 : 0x00FF37);
    }

    addAvatar(h, Xorigin) {
        this.avatarMask = this.scene.add.sprite(0, 0, 'avatar_mask').setOrigin(Xorigin, 0).setVisible(false);
        this.avatar = this.scene.add.sprite(0, 0, 'user_avatar').setOrigin(0.5, 0);

        this.avatar.setMask(this.avatarMask.createBitmapMask());
        this.avatarMask.flipX = this.isEnemy;

        if (this.isEnemy) {
            this.avatar.setTexture('enemy_avatar');
        }

        setImageSizeByHeight(this.avatarMask, h);
        setImageSizeByHeight(this.avatar, h);
    }

    update() {
        const x = this.avatarFrame.displayWidth * 0.12;
        const y = this.avatarFrame.displayHeight * 0.23;
        const offset = this.avatar.displayWidth * 0.47;

        if (this.isEnemy) {
            this.avatarMask.setPosition(window.innerWidth - x, y);
            this.avatar.setPosition(window.innerWidth - (x + offset), y);
        } else {
            this.avatarMask.setPosition(x, y);
            this.avatar.setPosition(x + offset, y);
        }
    }

    updateContent() {
        const enemyCount = this.battleScene.data.get('enemies').length;
        const enemyTotal = this.battleScene.data.get('enemies_total');
        const armyCount = this.battleScene.data.get('army_count');
        const armyTotal = this.battleScene.data.get('army_total');
        const objects = this.battleScene.data.get('objects');

        let proportion;

        if (this.isEnemy) {
            proportion = enemyCount / enemyTotal;
            this.counter.setText(enemyCount);

        } else {
            proportion = armyCount / armyTotal;
            this.counter.setText(armyCount);
        }

        this.updateProgressBar(proportion, this.isEnemy);

        if (enemyCount <= 0) {
            InfoTables.showWarningText('Все враги повержены');
            BattleUI.settings.finishBattleButton.makeInteractive();
            BattleUI.settings.finishBattleButton.blink();
            BattleUI.settings.fleeFromBattleButton.disable();
        }

        let devices = objects.find(o => o.getData('unit')['category'] == 'device');

        if (!devices) {
            InfoTables.showWarningText('Все предметы собраны');
        }
    }

    updateProgressBar(proportion, isEnemy) {
        const bar = this.progressBar;
        const segments = 27;
        const targetSegments = Math.floor(segments * proportion) - 1;

        this.currentSegments = this.currentSegments || segments;
        const segmentW = bar.width / segments;

        const timer = setInterval(() => {
            if (this.currentSegments > targetSegments) {
                if (isEnemy) {
                    const cropW = (segments - this.currentSegments) * segmentW;
                    bar.setCrop(cropW, 0, bar.width, bar.height);
                } else {
                    const cropW = this.currentSegments * segmentW;
                    bar.setCrop(0, 0, cropW, bar.height);
                }
                this.currentSegments--;
            } else {
                clearInterval(timer);
            }
        }, 50);
    }
}