import TimeManager from "../../../managers/TimeManager";
import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";

export default class ProcessIcon extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, data) {
        super(scene, x, y, children);

        this.info = data;
        const size = window.screen.height * 0.05;
        this.building = this.scene.game.registry.get('curBuilding');

        this.create(size);
    }

    create(size) {
        this.add(this.hex = this.scene.add.sprite(0, 0, 'UI', 'hex.png').setDisplaySize(size, size));
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setDisplaySize(size, size).setTint(0x000000));
        this.add(this.button = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setDisplaySize(size, size).setTint(0x48FF57));

        this.add(this.icon = this.scene.add.sprite(0, 0, 'items_icons', 'steel.png').setDisplaySize(size * 0.37, size * 0.37).setOrigin(0.5, 1.05));
        this.add(this.timer = this.scene.add.text(0, 0, this.info['duration'].toString(), TEXT_STYLE_BLUE).setOrigin(0.5, 0).setFontSize(size * 0.27));

        this.setIcon(this.info);
    }

    setIcon(data) {
        switch (data['category']) {
            case 'production':
                this.icon.setTexture('items_icons', `${data['name']}.png`);
                break;
            case 'building':
                this.icon.setTexture('items_icons', 'production.png');
                break;
            case 'exploration':
                this.icon.setTexture('battle_ui', `location_mark.png`).setTint(0xa5ff83);
                break;
            case 'healing':
                this.icon.setTexture('items_icons', 'heal.png');
                break;
            case 'unit_creation':
                this.icon.setTexture('unit_category_icons', `${data['name']}.png`);
                break;
        }
    }

    update(remainingTime) {
        this.timer && this.timer.setText(TimeManager.formatShortTime(remainingTime));
    }

    updateInfo(size, displayTimer = true) {
        this.hex.setDisplaySize(size, size);
        this.button.setDisplaySize(size, size);
        this.frame.setDisplaySize(size, size);
        this.icon.setDisplaySize(size * 0.37, size * 0.37);

        this.timer.setVisible(displayTimer);
        if (displayTimer) {
            this.timer.setFontSize(size * 0.27);
            this.icon.setDisplaySize(size * 0.37, size * 0.37).setOrigin(0.5, 1.05);
        } else {
            this.icon.setDisplaySize(size * 0.6, size * 0.6).setOrigin(0.5, 0.5);
        }
    }
}