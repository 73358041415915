import { ScrollManager } from "../../../../../../managers/UIController";
import AdsVideoCell from "./AdsVideoCell";


export default class AdsVideosContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, videoData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.videoData = videoData;

        this.addContent(tw, th);
    }

    addContent(tw, th) {
        const scrollW = tw * 0.29;
        const scrollH = th * 0.59;
        const spaceTop = th * 0.02;

        this.scroll = new ScrollManager(this.scene, {
            x: tw * 0.18,
            y: th * 0.02,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                top: spaceTop,
                bottom: spaceTop,
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.addCells();
    }

    addCells() {
        this.videoData.forEach((video, id) => {
            let cell = new AdsVideoCell(this.scene, 0, 0, [], this.tw * 0.29, this.tw * 0.0876, video, id);
            this.scroll.addItem(cell);
        });
    }

    update(level, data, processes) {
        for (let item of this.scroll.items) {
            item.update(level, data);

            if (processes) {
                item.checkProcessExists(processes);
            }
        }
    }
}