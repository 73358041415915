import AudioManager from "../../../../managers/AudioManager";
import BattleObstacle from "./BattleObstacle";

export default class BattleCrystall extends BattleObstacle {

    init(unitData, linkedIcon) {
        super.init(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = 2;

        this.shouldBeVisible = unitData['visible'] ?? false;
    }

    addContent() {
        super.addContent();
        
        if (!this.shouldBeVisible) {
            this.unitSprite.setVisible(false);
        }
    }

    die() {
        super.die();
        AudioManager.playSound('death', 'zazulic_nest');
    }

    playAnimation() { }
}