import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import eventBus from "../../../../../../services/eventBus";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import { multiplyObjectValues } from "../../../../../../utils/utils";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import ArrowButton from "../../../../../main/buttons/production/ArrowButton";
import StartProductionButton from "../../../../../main/buttons/production/StartProductionButton";


export default class TicketChangingCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, itemData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;

        this.itemData = itemData;
        this.itemName = itemData['item'];
        this.cost = { [this.itemData['currency']]: this.itemData['cost'] };

        this.resText = [];
        this.amountToProduce = 1;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'prod_cell.png').setDisplaySize(w, h));

        this.add(this.buttonIcon = this.scene.add.sprite(w * 0.43, 0, 'items_icons', 'check.png').setDisplaySize(h * 0.3, h * 0.3));
        this.add(this.button = new ActionButton(this.scene, w * 0.432, 0, [], 'table_button', h * 0.55, h * 0.55, 'green', this.changeTickets.bind(this)));

        this.add(this.minus = new ArrowButton(this.scene, w * -0.2, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'decrease', this));
        this.add(this.plus = new ArrowButton(this.scene, w * -0.04, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'increase', this));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        this.add(this.itemIcon = this.scene.add.sprite(w * -0.38, 0, 'items_icons', `${this.itemName}.png`)
            .setDisplaySize(h * 0.5, h * 0.5));

        UIController.arrangeItems({
            container: this,
            itemsData: this.cost,
            x: w * -0.19,
            y: h * 0.22,
            iconSize: h * 0.3,
            fontSize: h * 0.17,
            space: w * 0.2,
        });

        this.add(this.amountText = this.scene.add.text(w * -0.12, h * -0.22, '1').setOrigin(0.5, 0.5));
        this.updateText();
    }

    updateText() {
        const fontSize = this.height * 0.19;
        const items = this.scene.game.registry.get('items');

        let itemsData = multiplyObjectValues(this.cost, this.amountToProduce);
        UIController.updateItemsText(items, itemsData, this.itemsText);

        this.amountText.setText(this.amountToProduce.toString());
        this.amountText.setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize);
    }

    reset() {
        this.amount = 1;

        this.plus.makeInteractive();
        this.minus.makeInteractive();
        this.button.makeInteractive();
        this.updateText();

        updateUI(this.scene);
    }

    changeTickets() {
        apiClient.ads.changeTickets({
            item: this.itemData,
            amount: this.amountToProduce
        })
            .then(data => {
                InfoTables.showWarningText(data['message']);
                eventBus.emit('update-ads-content', data);
            })
            .catch(error => {
                console.error(error);
            });
    }
}