import apiClient from "../../../../../services/apiClient.js";
import SingleInfoTable from "../common/SingleInfoTable.js";
import { ScrollManager } from "../../../../../managers/UIController.js";
import TicketShopCell from "./components/TicketShopCell.js";
import eventBus from "../../../../../services/eventBus.js";


export default class AdsShopTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    create() {
        super.create();
        eventBus.on('update-ads-content', () => this.updateContent());
    }

    async update(category) {
        await super.update(category);        
        this.itemsData = this.parentContainer.tableData['shop'];

        if (!this.cellsAdded) {
            this.arrangeCells(this.width, this.height);
        }

        this.updateContent();
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.scroll.sizer.setOrigin(-0.05, 0);
        this.addContent();
    }

    addContent() {
        for (let key in this.itemsData) {
            let cell = new TicketShopCell(this.scene, 0, 0, [], this.width * 0.55, this.width * 0.06226, key, this.itemsData[key]);
            this.scroll.addItem(cell);
        }

        this.cellsAdded = true;
    }

    updateContent() {
        for (let item of this.scroll.items) {
            item.updateText();
        }
    }
}