export default class AudioManager {
    static musicOn = JSON.parse(localStorage.getItem('musicOn')) ?? true;
    static soundOn = JSON.parse(localStorage.getItem('soundOn')) ?? true;
    static curMusic;

    static init(music, audio) {
        if (this.initialized) {
            return;
        }
        this.music = music;
        this.audio = audio;
        
        this.curMusic = this.music['environment'];
        this.initialized = true;
    }

    static playSound(type, key) {
        if (this.soundOn) {
            this.audio[type][key].play();
        }
    }

    static setMusic(key) {
        this.curMusic = this.music[key];

        if (this.musicOn) {
            for (let music in this.music) {
                this.music[music].stop();
            }
            this.curMusic.play();
        }
    }

    static toggleMusic() {
        if (this.musicOn) {
            for (let music in this.music) {
                this.music[music].stop();
            }
        } else {
            this.curMusic.play();
        }
        
        this.musicOn = !this.musicOn;
        localStorage.setItem('musicOn', this.musicOn);
    }

    static toggleAudio() {
        for (let type in this.audio) {
            for (let sound in this.audio[type]) {
                this.audio[type][sound].setMute(this.soundOn);
            }
        }
        
        this.soundOn = !this.soundOn;
        localStorage.setItem('soundOn', this.soundOn);
    }
}
