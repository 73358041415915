import InfoTables from "../../../../../scenes/ui/InfoTables";
import ItemIcon from "../../../../main/icons/ItemIcon";
import InfoTableMainScroll from "../../../common/scroll/InfoTableMainScroll";
import BuildingProductionTable from "../production/BuildingProductionTable";
import ProductionCell from "../production/components/ProductionCell";

export default class GeneticCenterTable extends BuildingProductionTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            const items = ['embryo', 'blood', 'bone_brain', 'claw', 'eye', 'brain'];

            for (let item of items) {
                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.07, h * 0.07);
                icon.setIconData({ name: item, amount: data[item] });
    
                // Колбэк при клике на иконку
                // icon.setClickCallback(() => {
                //     InfoTables.showWarningText('Эмбрион');
                // });
    
                this.mainScroll.addItem(icon);
            }
        }));
    }

    addContent(w, h) {
        this.add(this.cloneCell = new ProductionCell(this.scene, w * -0.17, h * 0.22, [], w * 0.24, w * 0.06063, 'clone', this.prodData['data']['clone']));
    }

    async update(category) {
        await super.update(category);

        if (!this.cloneCell) {
            this.addContent(this.width, this.height);
        }
        
        this.cloneCell.update();
        this.cloneCell.checkProcessExists(this.prodData['processes']);

        let items = this.scene.game.registry.get('items');
        this.updateMainScroll(items);
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }
}