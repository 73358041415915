import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import { multiplyObjectValues } from "../../../../../../utils/utils";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import ArrowButton from "../../../../../main/buttons/production/ArrowButton";
import StartProductionButton from "../../../../../main/buttons/production/StartProductionButton";


export default class ProductionCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, itemName, itemData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.width = width;
        this.height = height;

        this.itemName = itemName;
        this.itemData = itemData;

        this.resText = [];
        this.amountToProduce = 1;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'prod_cell.png').setDisplaySize(w, h));

        this.add(this.buttonIcon = this.scene.add.sprite(w * 0.435, 0, 'items_icons', 'production.png').setDisplaySize(h * 0.3, h * 0.3));
        this.add(this.button = new StartProductionButton(this.scene, w * 0.432, 0, [], 'table_button', h * 0.55, h * 0.55, 'green', this));

        this.add(this.minus = new ArrowButton(this.scene, w * -0.2, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'decrease', this));
        this.add(this.plus = new ArrowButton(this.scene, w * -0.04, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'increase', this));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        this.add(this.itemIcon = this.scene.add.sprite(w * -0.38, 0, 'items_icons', `${this.itemName}.png`)
            .setDisplaySize(h * 0.5, h * 0.5));

        UIController.arrangeItems({
            container: this,
            itemsData: this.itemData['material'],
            x: w * -0.19,
            y: h * 0.22,
            iconSize: h * 0.3,
            fontSize: h * 0.17,
            space: w * 0.2,
        });

        this.add(this.amountText = this.scene.add.text(w * -0.12, h * -0.22, '1').setOrigin(0.5, 0.5));
        this.add(this.timeText = this.scene.add.text(w * 0.34, h * -0.18, '3:00').setOrigin(1, 0.5));

        this.updateText();
    }

    updateText() {
        const fontSize = this.height * 0.19;

        let items = this.scene.game.registry.get('items');
        let itemsData = multiplyObjectValues(this.itemData['material'], this.amountToProduce);
        UIController.updateItemsText(items, itemsData, this.itemsText);

        this.amountText.setText(this.amountToProduce.toString());
        this.amountText.setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize);

        const duration = this.itemData['production_time'] * this.amountToProduce;
        this.timeText.setText(TimeManager.formatFullTime(duration));
        this.timeText.setStyle(this.isProducing ? TEXT_STYLE_GREEN : TEXT_STYLE_BLUE).setFontSize(fontSize);
    }

    deactivate() {
        this.setAlpha(0.3);
        this.button.disable();
        this.plus.disable();
        this.minus.disable();
    }

    activate() {
        this.setAlpha(1);
        this.button.makeInteractive();
        this.plus.makeInteractive();
        this.minus.makeInteractive();
    }

    // data - информация о процессе производства
    startProduction(data) {
        this.isProducing = true;

        this.plus.disable();
        this.minus.disable();
        this.button.makeInteractive();
        this.timeText.setStyle(TEXT_STYLE_GREEN);

        TimeManager.createTimer(this.scene, this.itemName, data['duration'],
            this.finishProduction.bind(this),
            (remainingTime) => {
                this.timeText.setText(TimeManager.formatFullTime(remainingTime));
            }
        );

        const building = this.scene.game.registry.get('curBuilding');
        building.addProcessToStack(data);

        this.buttonIcon.setFrame('morion.png');
        this.emit('start_production', this);
    }

    async finishProduction() {
        const data = await apiClient.processes.finish({ category: 'production', name: this.itemName });
        console.log(data);
        console.log('process finished by timer');
        this.reset();
    }

    async finishByMorion() {
        const data = await apiClient.processes.finishByMorion({
            category: 'production',
            name: this.itemName,
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.scene.game.registry.set('items', data['items']);
        updateUI(this.scene);

        const building = this.scene.game.registry.get('curBuilding');
        const processIcon = building.getProcessFromStack(this.itemName);
        building.removeProcessFromStack(processIcon);
        this.reset();
    }

    reset() {
        this.isProducing = false;
        this.amountToProduce = 1;
        this.buttonIcon.setFrame('production.png');

        this.plus.makeInteractive();
        this.minus.makeInteractive();
        this.button.makeInteractive();
        this.updateText();

        updateUI(this.scene);
    }

    checkProcessExists(processes) {
        const process = processes[this.itemName];

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
            } else {
                this.startProduction(process);
            }
        }
    }

    update() {
        this.updateText();
        const building = this.scene.game.registry.get('curBuilding');
        this.deactivate();

        if (building.level >= this.itemData['required_building_level']) {
            this.activate();
        }
    }
}