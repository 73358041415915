import eventBus from "../../../../../services/eventBus.js";
import { TEXT_STYLE_BLUE } from "../../../../../utils/textStyles.js";
import SingleInfoTable from "../common/SingleInfoTable.js";
import { UnitXPText } from "../units/common/unitStatsModule.js";
import AdsVideosContainer from "./components/AdsVideosContainer.js";


export default class AdsCharacterTable extends SingleInfoTable {

    create() {
        this.addContent(this.width, this.height);
        super.create();

        eventBus.on('ads-level-up', (characterData) => {
            this.updateInfo(characterData);
        });
    }

    async update(category) {
        await super.update(category);
        this.characterData = this.parentContainer.tableData['character'];
        this.videoData = this.parentContainer.tableData['videos'];
        this.processData = this.parentContainer.tableData['processes'];

        if (!this.container) {
            this.add(this.container = new AdsVideosContainer(this.scene, 0, 0, [], this.width, this.height, this.videoData));
        }

        this.container.update(this.characterData['level'], this.videoData, this.processData);
        this.updateInfo(this.characterData);
    }

    addContent(w, h) {
        this.add(this.levelText = this.scene.add.text(0, 0, '-')
            .setOrigin(0.5, 0.5).setPosition(w * -0.305, h * -0.319)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.025));
        this.add(this.XP = new UnitXPText(this.scene, w * -0.215, h * -0.357, [], w, h));
    }

    updateInfo(unitData) {
        if (!unitData) {
            return;
        }
        this.setData('unit', unitData);
        this.levelText.setText(unitData['level']);
        this.XP.update(unitData['xp'], unitData['max_xp']);

        this.container.update(unitData['level']);
    }
}