import AudioManager from "../../../managers/AudioManager";
import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import Button from "../buttons/common/Button";

export default class UnitIcon extends Button {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height, color);

        this.isSelected = false;

        // Для теста
        // this.setUnitData({
        //     'category': 'soldier',
        //     'health': 60,
        //     'max_health': 100,
        //     'level': 2,
        //     'rank': 0,
        // });
    }

    create() {
        super.create();

        const w = this.width;
        const h = this.height;

        this.add(this.icon = this.scene.add.sprite(0, 0, 'unit_icons', '0.png'));
        this.add(this.darkCover = this.scene.add.sprite(0, 0, 'unit_icons', '0.png')
            .setTint(0xFF2F2F).setCrop(0, 0, 0, 0));

        this.add(this.healIcon = this.scene.add.sprite(w * -0.07, 0, 'items_icons', 'heal.png').setDisplaySize(w * 0.5, w * 0.5).setAlpha(0));

        this.add(this.statusIcon = this.scene.add.sprite(0, 0, 'items_icons', 'upgrade.png').setAlpha(0));
        this.add(this.lvl = this.scene.add.text(0, 0, '').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE));

        this.obj.setTexture('unit_icons').setFrame('frame.png');
        this.bringToTop(this.obj);

        this.created = true;
        this.resize(w, h);

        this.initCallbacks();
    }

    initCallbacks() {
        this.clickCallback = () => { };
        this.pointerOverCallback = () => { };
        this.pointerOutCallback = () => { };
    }

    setUnitData(data, index = 0) {
        this.unitData = data;
        this.index = index;
        this.updateContent(data);
    }

    updateContent(data) {
        this.lvl.setText(data['level']);
        if (!this.enlarged) this.setIconTexture();
        this.showHealthFill();

        this.statusIcon.setAlpha(0);
        this.healIcon.setAlpha(0);

        if (data['health'] <= 0) {
            this.darkCover.setTint(0x5E5E5E);
            if (this.state == 'active') this.disable();
        }

        if (data['health'] < data['max_health']) {
            this.statusIcon.setAlpha(1);
            this.statusIcon.setFrame('heal.png');

        } else if (data['category'] == 'soldier' && data['rank'] < Math.floor(data['level'] / 5)) {
            this.statusIcon.setAlpha(1);
            this.statusIcon.setFrame('upgrade.png');
        }

        if (data['status'] == 'healing') {
            // this.darkCover.setTint(0x5E5E5E).setCrop(0, 0, this.darkCover.width, this.darkCover.height);
            this.healIcon.setAlpha(1);
        }
    }

    setIconTexture() {
        const category = this.unitData['category'];
        let texture;

        if (category == 'soldier') {
            texture = `${this.unitData['rank']}.png`;

        } else if (['commander', 'character'].includes(category)) {
            texture = `${this.unitData['name']}.png`;

        } else if (category == 'robot') {
            texture = `${this.unitData['name']}.png`;

        } else {
            texture = `${this.unitData['category']}.png`;
        }

        this.icon.setTexture('unit_icons', texture);
        this.darkCover.setTexture('unit_icons', texture);
    }

    showHealthFill() {
        if (!this.unitData) return;

        const health = this.unitData['health'];
        const maxHealth = this.unitData['max_health'];

        let cropH = this.darkCover.height * (health / maxHealth);

        this.darkCover.setCrop(0, cropH, this.darkCover.width, this.darkCover.height);
    }

    setClickCallback(callback) {
        this.clickCallback = callback;
    }

    setPointerOverCallback(callback) {
        this.pointerOverCallback = callback;
    }

    setPointerOutCallback(callback) {
        this.pointerOutCallback = callback;
    }

    click() {
        AudioManager.playSound('click', 'icon');
        this.clickCallback();
    }

    pointerOver() {
        super.pointerOver();
        this.pointerOverCallback();
    }

    pointerOut() {
        super.pointerOut();
        this.obj.setAlpha(this.isSelected ? 1 : 0.001);
        this.pointerOutCallback();
    }

    select(color = 'green') {
        this.setColor(color);
        this.isSelected = true;
        this.obj.setAlpha(1);
    }

    deselect() {
        this.setColor();
        this.isSelected = false;
        this.obj.setAlpha(0.001);
    }

    activate() {
        this.disable();
        this.obj.setTint(0x14ff00);
        this.setState('active');
    }

    resize(w, h) {
        this.width = w;
        this.height = h;

        this.obj.setDisplaySize(w, h);

        if (!this.created) {
            return;
        }

        this.icon.setDisplaySize(w, h);
        this.darkCover.setDisplaySize(w, h);

        const ratio = this.flipped ? -1 : 1;

        this.statusIcon.setDisplaySize(w * 0.15 * ratio, w * 0.15).setPosition(w * 0.23 * ratio, w * -0.44);
        this.showHealthFill();

        this.lvl.setPosition(w * 0.36 * ratio, w * -0.25).setFontSize(w * 0.13);
    }

    flip() {
        this.flipped = !this.flipped;
        this.icon.flipX = true;
        this.obj.flipX = true;
        this.darkCover.flipX = true;
        this.lvl.setPosition(-this.lvl.x, this.icon.displayWidth * -0.25);
        this.statusIcon.setPosition(-this.statusIcon.x, this.icon.displayWidth * -0.44);
    }

    enlarge(category) {
        const texture = this.unitData['name'] + '_icon_large';
        this.darkCover.setTexture(texture);
        this.icon.setTexture(texture);
        this.obj.setTexture('icon_frame_large');

        this.enlarged = true;
    }

    linkMapUnit(unit) {
        this.mapUnit = unit;
    }

    makeInteractive() {
        super.makeInteractive();

        this.obj.setTexture('unit_icons').setFrame('frame.png');
        !this.selected && this.obj.setAlpha(0.001);
        this.setColor(this.color);
        this.resize(this.width, this.height);
    }

    disable() {
        this.obj.disableInteractive();
        this.disabled = true;

        if (this.enlarged) {
            this.obj.setTexture('icon_frame_large');
        } else {
            this.obj.setTexture('unit_icons').setFrame('frame.png');
        }

        this.obj.setDisplaySize(this.width, this.height);
        this.obj.setTint(0x939393).setAlpha(1);
    }
}