import InfoTables from "../../../../../scenes/ui/InfoTables";
import ItemIcon from "../../../../main/icons/ItemIcon";
import InfoTableMainScroll from "../../../common/scroll/InfoTableMainScroll";
import BuildingProductionTable from "../production/BuildingProductionTable";
import MakeUnitFromCloneCell from "./common/MakeUnitFromCloneCell";

export default class ResearchLabTable extends BuildingProductionTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            const items = ['clone', 'crystall', 'yellow_crystall', 'red_crystall', 'purple_crystall', 'brown_crystall'];

            for (let item of items) {
                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.07, h * 0.07);
                icon.setIconData({ name: item, amount: data[item] });

                this.mainScroll.addItem(icon);
            }
        }));

        this.addContent(w, h);
    }

    addContent(w, h) {
        this.add(this.unitCreationCell = new MakeUnitFromCloneCell(this.scene, w * -0.17, h * 0.212, [], w * 0.24, w * 0.06063, 'irradiated'));
    }

    async update(category) {
        await super.update(category);

        let items = this.scene.game.registry.get('items');
        this.updateMainScroll(items);
        this.unitCreationCell.update();
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }
}