import InfoTableContainer from './common/InfoTableContainer.js';
import AdsShopTable from '../tables/ads/AdsShopTable.js';
import apiClient from '../../../../services/apiClient.js';
import AdsAchievementTable from '../tables/ads/AdsAchievementTable.js';
import AdsCharacterTable from '../tables/ads/AdsCharacterTable.js';
import eventBus from '../../../../services/eventBus.js';
import InfoTableMainScroll from '../../common/scroll/InfoTableMainScroll.js';
import ItemIcon from '../../../main/icons/ItemIcon.js';

export default class AdsTableContainer extends InfoTableContainer {

    tableData = {};

    create() {
        super.create();
        const w = this.w;
        const h = this.h;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            const items = ['ticket', 'golden_ticket'];

            for (let item of items) {
                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.07, h * 0.07);
                icon.setIconData({ name: item, amount: data[item] });
                icon.setClickCallback(() => { });

                this.mainScroll.addItem(icon);
            }
        }));

        eventBus.on('update-ads-content', () => this.updateScroll());
    }

    addInfoTables() {
        this.tableLeft = null;
        this.tableLeft = new AdsAchievementTable(this.scene, 0, 0, [], this.w, this.h, 'ads_achievements');
        this.tableCenter = new AdsCharacterTable(this.scene, 0, 0, [], this.w, this.h, 'ads_character');
        this.tableRight = new AdsShopTable(this.scene, 0, 0, [], this.w, this.h, 'ads_shop');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }

    async open(position) {
        const data = await apiClient.ads.getData();

        if (data['status'] !== 'success') {
            return;
        }

        for (let key in data['data']) {
            this.tableData[key] = data['data'][key];
        }

        await super.open(position);
        this.updateScroll();
    }

    updateScroll() {
        const items = this.scene.game.registry.get('items');
        this.mainScroll.updateContent(items);
    }

    showButtons() {
        super.showButtons();
        this.bringToTop(this.mainScroll);
    }
}
