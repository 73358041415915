import barrierData from "../../../../../../config/scenes/barrierData";
import sceneNames from "../../../../../../config/scenes/sceneNames";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import { getObjectValue } from "../../../../../../utils/utils";
import Button from "../../../../../main/buttons/common/Button";


export default class ProcessCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, processKey, data) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.width = width;
        this.height = height;

        this.processKey = processKey;
        this.processData = data;

        const table = InfoTables.curTable;
        this.itemData = table.getData('data')['item_data'];
        this.buildingData = table.getData('data')['building_data'];
        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'factory_cell.png').setDisplaySize(w, h));
        this.add(this.button = new Button(this.scene, w * 0.468, 0, [], 'table_button', h * 0.54, h * 0.54, 'green'));
        this.add(this.buttonIcon = this.scene.add.sprite(w * 0.468, 0, 'items_icons', 'check.png').setDisplaySize(h * 0.25, h * 0.25));

        this.add(this.name = this.scene.add.text(w * -0.34, h * -0.22, '')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.22));

        this.add(this.status = this.scene.add.text(w * -0.34, h * 0.22, 'Процесс завершен')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.22));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        let frame = this.processKey; // По умолчанию используем название процесса (для производства)
        let texture = 'items_icons';
        let name = this.processKey;

        switch (this.processData['category']) {
            case 'production':
                name = this.itemData[this.processData['building_name']][frame]['name'];
                break;
            case 'container_hacking':
                name = this.itemData[this.processData['building_name']][frame]['name'];
                texture = 'container_icons';
                break;
            case 'building':
                name = getObjectValue(this.buildingData, frame)['name'];
                console.log(getObjectValue(this.buildingData, frame));
                frame = 'production';
                break;
            case 'building_construction':
                name = barrierData[frame]['name'];
                frame = 'production';
                break;
            case 'exploration':
                name = sceneNames[frame];
                texture = 'battle_ui';
                frame = 'location_mark';
                break;
            case 'healing':
                name = this.processData['unit']['name'] ?? 'Солдат';
                frame = 'heal';
                break;
            case 'unit_creation':
                switch (frame) {
                    case 'soldier': name = 'Солдат'; break;
                    case 'mutant': name = 'Мутант'; break;
                    case 'irradiated': name = 'Облученный'; break;
                }
                texture = 'unit_category_icons';
                break;
        }

        this.name.setText(name);

        this.add(this.icon = this.scene.add.sprite(w * -0.435, 0, texture, `${frame}.png`).setDisplaySize(h * 0.55, h * 0.55));
        this.add(this.amountText = this.scene.add.text(w * 0.39, h * -0.22, this.processData['item_amount'] ?? '1')
            .setOrigin(1, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.22));
    }
}