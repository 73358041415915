import AudioManager from "../../../../../managers/AudioManager";
import Lightning from "../../common/Lightning";
import BattleCharacter from "./BattleCharacter";

export default class BattleTechNastya extends BattleCharacter {
    
    create(unitData, linkedIcon) {
        super.create(unitData, linkedIcon);
        
        this.getData('unit')['range'] = 1;
        this.getData('config')['target'] = 'extra';
        this.getData('config')['priority'] = 'device';
    }

    async findTarget() {
        if (this.isSearching) {
            if (this.hasTarget() && this.target.isDead()) {
                this.setTarget(null);
                this.isSearching = false; // Сбрасываем состояние поиска
            } else {
                return;
            }
        }
        await super.findTarget();
    }

    dealDamageToTarget(target) {

        if (this.isAttacking) {
            this.hitTarget(target);

        } else if (!this.isDead()) {
            this.offAnimations();
            this.pickUpItem();
        }
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;

        const positions = {
            'right': { x: w * 0.4, y: w * -0.15 },
            'left': { x: w * -0.4, y: w * -0.15 },
            'down-r': { x: w * 0.2, y: w * -0.1 },
            'down-l': { x: w * -0.2, y: w * -0.1 },
            'up-r': { x: w * 0.3, y: w * -0.3 },
            'up-l': { x: w * -0.3, y: w * -0.3 },
            'up': { x: w * 0.05, y: w * -0.4 },
            'down': { x: 0, y: w * 0.2 },
        };

        const lightning = new Lightning(this.scene, x + positions[dir].x, y + positions[dir].y, 'burner_flame');
        lightning.create(x, y, target, 500);

        AudioManager.playSound('attack', 'techNastya');
    }

    chooseAndPlayAttackAnim(target) {
        this.setDirection(target);
        this.playAnimation(this.isAttacking ? 'attack' : 'lift');
    }

    search() {
        const searchTime = 10000 - this.getData('unit')['search_speed'];
        this.isSearching = true;

        this.playAnimation('search');
        setTimeout(() => {
            this.isSearching = false;
        }, searchTime);
    }

    pickUpItem() {
        this.isSearching = false;
        this.playAnimation('lift');

        this.unitSprite.on('animationcomplete', () => {
            this.unitSprite.off('animationcomplete');

            if (this.hasTarget() && !this.target.isDead()) {
                const itemName = this.target.getData('unit')['name'];
                this.displayPopText(' + 1', itemName);

                const extraRewards = this.scene.data.get('extra_rewards');
    
                if (!extraRewards[itemName]) {
                    extraRewards[itemName] = 1;
                } else {
                    extraRewards[itemName]++;
                }

                this.target.die();
                this.setTarget(null);
            }

            this.search();
        });
    }
}