import bridge from "@vkontakte/vk-bridge";
import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_GREY, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import eventBus from "../../../../../../services/eventBus";


export default class AdsVideoCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, videoData, videoId) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;
        this.videoData = videoData;
        this.videoId = videoId;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'building_cell.png').setDisplaySize(w, h));
        this.add(this.buttonIcon = this.scene.add.sprite(w * 0.435, 0, 'items_icons', 'check.png').setDisplaySize(h * 0.25, h * 0.25));
        this.add(this.button = new ActionButton(this.scene, w * 0.437, 0, [], 'table_button', h * 0.44, h * 0.44, 'green', this.watch.bind(this)));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        this.add(this.icon = this.scene.add.image(w * -0.33, 0, 'video_icon')
            .setDisplaySize(h * 1.14, h * 1.14));

        this.add(this.levelText = this.scene.add.container(w * -0.33, 0, [
            this.scene.add.text(0, 0, `${this.videoData['required_character_level']}`)
                .setOrigin(0.5, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(this.height * 0.25),
            this.scene.add.text(0, 0, `уровень`).setOrigin(0.5, -0.2).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.15)
        ]));

        UIController.arrangeItems({
            container: this,
            itemsData: this.videoData['reward'],
            x: w * -0.07,
            y: h * 0.2,
            iconSize: h * 0.25,
            fontSize: h * 0.17,
            space: w * 0.2,
        });

        this.add(this.title = this.scene.add.text(w * -0.12, h * -0.12, 'Раз в').setOrigin(0, 0.5));
        this.add(this.timeText = this.scene.add.text(w * 0.34, h * -0.12, '3:00').setOrigin(1, 0.5));
        this.updateText();
    }

    updateText() {
        const fontSize = this.height * 0.15;

        const duration = this.videoData['interval'];
        this.timeText.setText(TimeManager.formatFullTime(duration));
        this.timeText.setStyle(this.isCoolingDown ? TEXT_STYLE_GREY : TEXT_STYLE_BLUE).setFontSize(fontSize);

        this.title.setStyle(TEXT_STYLE_ORANGE).setFontSize(fontSize);
    }

    deactivate() {
        this.setAlpha(0.3);
        this.button.disable();
        this.levelText.setAlpha(1);
    }

    activate() {
        this.setAlpha(1);

        if (!this.isCoolingDown) {
            this.button.makeInteractive();
            this.buttonIcon.setFrame('play.png');
        }

        this.levelText.setAlpha(0);
    }

    disable() {
        this.button.disable();
    }

    async watch() {
        const videoId = this.videoId;
        const cell = this;
        this.disable();

        this.reward(videoId);

        // bridge.send('VKWebAppCheckNativeAds', { ad_format: 'reward' })
        //     .then((data) => {
        //         if (data.result) {
        //             // Предзагруженная реклама есть.
        //             bridge.send('VKWebAppShowNativeAds', { ad_format: 'reward' })
        //                 .then((data) => {
        //                     if (data.result) {
        //                         console.log('Реклама показана');

        //                         // Add the reward after watching a video 
        //                         cell.reward(videoId);

        //                     } else {
        //                         console.log('Ошибка при показе рекламы');
        //                     }
        //                 })
        //                 .catch((error) => { console.log(error); });

        //         } else {
        //             console.log('Рекламные материалы не найдены.');
        //         }
        //     })
        //     .catch((error) => { console.log(error); });
    }

    reward(videoId) {
        const cell = this;

        apiClient.ads.videoReward({ video_id: videoId })
            .then(data => {
                InfoTables.showWarningText(data['message']);
                eventBus.emit('update-ads-content', data);

                cell.startCooldown(data['process']);
            })
            .catch((error) => { console.error(error); });
    }

    startCooldown(data) {
        this.isCoolingDown = true;

        this.disable();
        this.buttonIcon.setFrame('check.png');
        this.timeText.setStyle(TEXT_STYLE_GREY);

        TimeManager.createTimer(this.scene, `video_${this.videoId}`, data['duration'],
            this.finishCooldown.bind(this),
            (remainingTime) => {
                this.timeText.setText(TimeManager.formatFullTime(remainingTime));
            }
        );
    }

    async finishCooldown() {
        await apiClient.processes.finish({ category: 'video_cooldown', name: `video_${this.videoId}` });
        this.reset();
    }

    reset() {
        this.isCoolingDown = false;
        this.button.makeInteractive();
        this.updateText();
    }

    checkProcessExists(processes) {
        const process = processes[`video_${this.videoId}`];

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
            } else {
                this.startCooldown(process);
            }
        }
    }

    update(level, data) {
        this.updateText();
        this.deactivate();

        if (level >= this.videoData['required_character_level']) {
            this.activate();
        }
    }
}